.stepIcon {
  border-radius: 0dvh;
}
.parcelDetails {
  width: Fill (1,076px)px;
  height: Hug (190px)px;
  padding: 32px;
  gap: 16px;
  border-radius: 16px;
  /* opacity: 0px; */
  background-color: white;
}
.parcelDetailsTitle {
  /* //styleName: Text Uppercase/md; */
  /* fon */
  /* font-family: Roboto; */
  text-transform: uppercase;
  /* font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #FF0000; */
  /* font-family: ; */
}

.root {
  /* position: relative; */
  /* padding: var(--mantine-spacing-md); */
  transition: border-color 150ms ease;

  /* &[data-checked] {
    border-color: var(--mantine-primary-color-filled);
  }

  @mixin hover {
    @mixin light {
      background-color: var(--mantine-color-gray-0);
    }

    @mixin dark {
      background-color: var(--mantine-color-dark-6);
    }
  } */
}

.label {
  /* font-family: var(--mantine-font-family-monospace);
  font-weight: bold;
  font-size: var(--mantine-font-size-md);
  line-height: 1.3;
  color: var(--mantine-color-bright); */
}

/* .description {
  margin-top: 8px;
  color: var(--mantine-color-dimmed);
  font-size: var(--mantine-font-size-xs);
} */